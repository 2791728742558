.payment .main__section {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    padding: 24px 0;
}

.container.payment {
    max-width: 960px;
}

.payment .form__group .form__field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.form__field label {
    display: block;
    max-width: 150px;
    margin-right: 10px;
    font-weight: 400;
}

@media only screen and (min-width: 675px) {
    .payment .form__group .form__field {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 500px) {
    .form__field label {
        min-width: 100px;
        display: block;
        margin-right: 10px;
        font-weight: 400;
    }
}

@media only screen and (max-width: 400px) {
    .form__field label {
        min-width: 40px;
    }
}

@media only screen and (min-width: 500px) {
    .form__field label {
        min-width: 150px;
    }
}

.payment .label {
    display: flex;
    height: 100%;
    font-size: 14px;
}

@media only screen and (min-width: 675px) {
    .payment .label {
        min-width: 160px;
    }
}

.payment .form__field input {
    height: 30px;
    color: #636362;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    width: 100%;
    padding-left: 11px;
    padding-right: 11px;
    font-size: 1em;
}

.form__bottom {
    margin-bottom: 40px;
}

.payment .form__field {
    margin-bottom: 16px;
}

#paymentMessage {
    /* border:1px solid black;  */
    padding: 15px;
}

.payment .form__group {
    max-width: 650px;
    border-radius: 10px;
    margin: auto;
    padding: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #e6e6e6;
    color: #6d6e70;
}

.payment .form__title {
    margin: auto;
    display: flex;
    justify-content: flex-start;
    color: #d75094;
    margin-bottom: 30px;
    font-size: 24px;
}

.payment .form__title h2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 1.15;
    margin-top: 1em;
}

.card_parts {
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    text-align: right;
}

.card_parts input {
    max-width: 100%;
    margin: 2px;
    background-color: white;
}

.payment .form__action:disabled {
    background-color: var(--colour__grey--dark);
    color: red;
}

.payment form {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 0.9em;
}

.payment form p {
    margin-bottom: 1em;
}

.card_parts.expiryInputLabel {
    display: flex;
    align-items: center;
}

.card_parts.expiryInputLabel label {
    text-align: end;
    margin-right: 12px;
    height: 100%;
    font-size: 14px;
    width: 30%;
}

.card_parts.expiryInputLabel input {
    width: 20%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.form__info p {
    text-align: left;
    margin-bottom: 1.7em;
}

.payment .form__action {
    display: inline-block;
    width: 250px;
    padding: 10px;
    border-radius: 5px;
    border: 0;
    color: var(--colour__white) !important;
    background-color: var(--colour__secondary);
    font-size: 18px;
    font-weight: 500;
    text-decoration: none !important;
    text-align: center;
    line-height: 1;
    transition: var(--transistion);
    cursor: pointer;
    height: 40px;
}

.billingAddressPostcodeFieldAndButton {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.postcodeField {
    margin-right: 10px;
}

.card_parts.countyField {
    width: 50%;
    margin-right: 10px;
}

.findAddressFieldButton {
    width: 50%;
    font-family: neo-sans, sans-serif;
    text-align: center;
    color: white;
    background-color: var(--colour__primary);
    border: 0px none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    letter-spacing: 0.6px;
}

.findAddressFieldButton:hover {
    cursor: pointer;
}

.findAddressFieldButton:disabled {
    background-color: var(--colour__grey--dark);
}

.paymentHr {
    margin-top: 40px;
}

.form__title.paymentDetailTitle h2 {
    margin-top: 6px;
}

.addressListSelect {
    border: 0;
    width: 100% !important;
    margin: 2px;
    font-size: 1em;
    padding: 1px 8px 1px 10px;
    height: 30px;
    color: #636362;
}

.paymentFormMessage {
    margin-bottom: 10px;
    line-height: 1.5em;
}

.confirmClinicPopupErrorMessage {
    margin-top: 10px;
    text-align: center;
}