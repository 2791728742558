:root {
    --colour__grey: #E1E1E1;
    --colour__secondary: #D75094;
    --colour__white: #FFF;
    --font: neo-sans, sans-serif;
}

body {
    font-weight: 400;
    font-family: var(--font);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.main {
    margin: 0 auto;
    padding-top: 55px;
    text-align: center;
}

.container {
    max-width: 1235px;
    width: 100%;
    margin: 0 auto;
    padding: 0 17.5px;
}

.header .container{
    padding: 0px;
    padding-right: 17.5px;
    padding-left: 17.5px;
}

@media screen and (min-width: 768px){
    .header .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

/* header */

.header {
    line-height: 1.1;
    border-bottom: 1px solid var(--colour__grey--dark);
}

.header__inner {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (min-width: 750px) {
    .header__inner  {
        padding-top: 23px;
        padding-bottom: 18px;
    }
}

.header__p {
    text-align: center;
    font-size: 1.8rem;
    color: var(--colour__font);
    font-weight: 500;
    margin-bottom: 15px;
}

@media only screen and (min-width: 450px) {
    .header__p {
        font-size: 2.3rem;
    }
}

@media only screen and (min-width: 750px) {
    .header__p {
        margin-bottom: 0;
        position: absolute;
        top: 54px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.logo img {
    max-width: 100px;
}

@media only screen and (min-width: 450px) {
    .logo img {
        max-width: none;
    }
}

.logo--iprs {
    margin-bottom: 8px;
}

@media only screen and (min-width: 450px) {
    .logo--iprs {
        margin-bottom: 16px;
    }
}


.assessment-type-selection p {
    font-size: 1.8rem;
}

/* Feature-boxes */
.feature-boxes {
    margin-top: 35px;
    margin-bottom: 43px;
}

.feature-box {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 360px;
    border-radius: 20px;
}

.feature-box:hover{
    cursor: pointer;
}

.feature-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.feature-box p {
    position: absolute;
    bottom: 40px;
    margin: 0 12px;
}

@media only screen and (min-width: 600px){
    .feature-box {
        height: 400px;
    }
    .feature-box__hover-div {
        padding: 50px 72px 120px;
        font-size: 1.3rem;
    }
}

@media only screen and (min-width: 750px){
    .feature-box {
        width: calc(50% - 12px);
        height: 420px;
    }
}

@media only screen and (min-width: 750px){
    .feature-boxes {
        display: flex;
        justify-content: space-between;
    }
}

@media only screen and (min-width: 750px){
    .feature-box-1 {
        margin-bottom: 0;
    }
    .feature-box__hover-div {
        font-size: 0.8rem
    }
}

@media only screen and (min-width: 900px){
    .feature-box__hover-div {
        padding: 50px 72px 120px;
        font-size: 1.3rem;
    }
}

.green-button, a.green-button {
    display: inline-block;
    background-color: var(--colour__primary);
    color: var(--colour__white);
    font-weight: 500;
    padding: 8px 12px;
    text-decoration: none !important;
    font-size: 1.8rem;
    transition: all 200ms ease-in-out;
}

.info__button {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 10px;
    right: 9px;
    z-index: 20;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

.feature-box__hover-div {
    transition: all 300ms ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #E1E1E1;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 55px 50px;
    font-size: 1.2rem;
}

@media only screen and (min-width: 900px){
    .feature-box__hover-div {
        padding: 50px 72px 120px;
        font-size: 1.2rem;
    }
}

@media only screen and (min-width: 1000px){
    .feature-box__hover-div {
        padding: 50px 72px 120px;
        font-size: 1.4rem;
    }
}

.feature-box-1 {
    margin-bottom: 16px;
}


.home-page .bottom-paragraph {
    margin-bottom: 44px;
}

/* Footer */

.footer{
    background: none;
    background-color: var(--colour__secondary);
    padding: 0px;
}

.footer__inner {
    padding-top: 40px;
    padding-bottom: 40px;
}

.footer__inner a {
    display: inline-block;
    font-size: 1.5rem;
    margin-bottom: 30px;
}

.footer__inner p{
    font-size: 83%;
}

.footer__inner a, .footer__inner p {
    color: var(--colour__white);
}

@media only screen and (min-width: 750px){
    .footer__inner {
        display: flex;
        justify-content: space-between;
        padding-top: 67px;
        padding-bottom: 48px;
    }
}

.optionPageError{
    display: flex;
    justify-content: center;
}

.optionPageError p{
    width: 600px;
    margin-bottom: 50px;
}